import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSmall from "../components/media/BannerSmall"
import Header from "../components/Header/Header"
import Cheers from "../components/media/Cheers"
import ContactForm from "../components/contactform"

const Kegs = () => (
  <Layout>
    <SEO title="Kegs for Weddings and Parties in Guildford" />
    <Header />
    <section className="Hero">
      <BannerSmall />
    </section>
    <main>
      <section>
        <div className="TwoColumn">
          <div className="textSection">
            <h2>Want something different for your wedding or party?</h2>
            <p>
              Working with our suppliers, we can source pretty much any beer for
              your next event. We can also provide the pumps needed to pour the
              perfect pint everytime.
            </p>
          </div>
          <Cheers filename="lindr.jpg" />
        </div>
      </section>
      <section>
        <div className="TwoColumn even">
          <Cheers filename="window.jpg" />
          <div className="textSection">
            <h2>Its not just beer we can supply</h2>
            <p>
              We can provide all of the cider, wine, gin, spirits and soft
              drinks to make your next party a success. Just get in contact
              below and we'll handle the rest.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="OneColumn">
          <h2>What are you waiting for? Let's get the party started!</h2>
          <p>Fill out the form below and we will get back to you ASAP</p>
          <ContactForm />
        </div>
      </section>
    </main>
  </Layout>
)

export default Kegs
